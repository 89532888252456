import React, { useEffect, useState, useRef } from "react"
import { useForm } from "react-hook-form"
import {
  ErrorMessages,
  States,
  CompanyNames,
  EmailSettings
} from "../../constants"
import {
  TrvInput,
  TrvPhoneInput,
  TrvDropdown,
  TrvDateInput
} from "trv-ebus-tcom-reactcomponents"
import "./scss/main.scss"
import { TdsAlert } from "@trv-tds/react"
import TravelersIncentiveEmailTemplate from "../email-templates/travelersIncentiveEmailTemplate"
import { v4 as uuidv4 } from "uuid"
import { store } from "../../state/store"
import Recaptcha from "../../components/recaptcha/recaptcha"
import getFullStateName from "../../utilities/getFullStateName"
import formatDate from "../../utilities/formatDate"
import {emailHeaders, emailPayload} from '../email-templates/emailRequest'
import {
  recaptchaCalled,
  sendEmailPayloads,
  sentWithRecaptcha
} from "../../services/formsService"
import "../styles.scss"

const TravelersIncentiveForm = () => {
  const [isClient, setIsClient] = useState(false)
  const [isServiceError, setIsServiceError] = useState(false)
  const [isGoogleServiceError, setIsGoogleServiceError] = useState(false)
  const [isSubmissionInProgress, setIsSubmissionInProgress] = useState(false)
  const [emailBody, setEmailBody] = useState(null)
  const returnUrl = "/services/incentives/thankyou"

  const alertRef = useRef(null)

  const state = store.getState()

  const recaptchaEnabled =
    state.config.RECAPTCHA_ENABLED === "true" ? true : false

  useEffect(() => {
    setIsClient(true)
  }, [])

  var year = new Date().getFullYear()
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm()

  const googleServiceErrorHandler = () => {
    setIsGoogleServiceError(true)
  }

  const onSubmit = async (formdata) => {
    if (!isGoogleServiceError) {
      setIsSubmissionInProgress(true)

      const body = TravelersIncentiveEmailTemplate(formdata)
      let formPayload = {}
      formPayload = {
          email_body: {
            "topic": state.config.EEF_EVENT,
            "payload": {
                ...emailPayload('INCENTIVE_FORM', state.config, formdata),
                "metaData": [
                    {
                        "key": "ProgramAdministratorCompany",
                        "value": formdata.companyName
                    },
                    {
                        "key": "ProgramAdministratorName",
                        "value": formdata.generalAgentName
                    },
                    {
                        "key": "ProgramAdministratorCity",
                        "value": formdata.city
                    },
                    {
                        "key": "ProgramAdministratorState",
                        "value": getFullStateName(formdata.state)
                    },
                    {
                        "key": "TrvAgencyname",
                        "value": formdata.trvAgencyName
                    },
                    {
                        "key": "TrvAgencyCode",
                        "value": formdata.trvAgencyCode
                    },
                    {
                        "key": "TrvAgencyCity",
                        "value": formdata.agencyCity
                    },
                    {
                        "key": "TrvAgencyState",
                        "value": getFullStateName(formdata.agencyState)
                    },
                    {
                        "key": "TrvAgencyPhone",
                        "value": formdata.phone
                    },
                    {
                        "key": "TrvAgencyContactName",
                        "value": formdata.contactName
                    },
                    {
                        "key": "AccountName",
                        "value": formdata.accountName
                    },
                    {
                        "key": "PolicyNumber",
                        "value": formdata.policyNumber
                    },
                    {
                        "key": "PolicyEffectiveDate",
                        "value": formatDate(formdata.policyEffectiveDate)
                    },
                    {
                      "key": "GrossPremiumWritten",
                      "value" : formdata.grossWrittenPremium
                    },
                    {
                        "key": "AppData",
                        "value": state.config.APP_CODE_INCENTIVE
                    },
                    {
                        "key": "sourceSiloRegion",
                        "value": state.config.EVENT_REGION
                    }
                ]
            },
            "headers": emailHeaders(state.config)
        }
        }

      if (recaptchaCalled()) {
        setEmailBody(formPayload)
      } else {
        const emailSent = await sendEmailPayloads(formPayload, returnUrl)
        if (!emailSent) {
          serviceErrorHandler()
        }
        setIsSubmissionInProgress(false)
      }
    } else {
      serviceErrorHandler()
    }
  }

  const submitWithRecaptcha = async (recaptchaResponse, siteType) => {
    const emailSent = await sentWithRecaptcha(
      recaptchaResponse,
      siteType,
      emailBody,
      returnUrl
    )

    if (!emailSent) {
      serviceErrorHandler()
    }
    setIsSubmissionInProgress(false)
  }

  const serviceErrorHandler = () => {
    setIsServiceError(true)
    window.scrollTo(0, alertRef.current.offsetTop - 100)
  }

  return isClient ? (
    <>
      <p className="tds-margin-top-large">All fields are required.</p>
      {isServiceError && (
        <TdsAlert type="error" ref={alertRef}>
          {ErrorMessages.Service_Error}
        </TdsAlert>
      )}

      <div className="tds-row tds-margin-top-x-large">
        <div className="tds-col-xl-10">
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            {recaptchaEnabled && (
              <Recaptcha
                serviceCall={submitWithRecaptcha}
                siteType={state.config.RECAPTCHA_SITE_TYPE}
                googleServiceError={googleServiceErrorHandler}
              />
            )}
            <div className="tds-col-xl-9 tds-col-md-9">
              <TrvDropdown
                register={register}
                name="companyName"
                label="Issuing Insurance Company Name"
                options={CompanyNames}
                required={true}
                hint="Select a Company Name..."
                defaultValue=""
                errors={errors.companyName}
                validityMessages={{
                  required: ErrorMessages.Empty_CompanyName
                }}
              />
            </div>
            <div className="tds-col-xl-9 tds-col-md-9">
              <TrvInput
                register={register}
                name="generalAgentName"
                label="Name Of General Agent OR Program Administrator"
                required={true}
                pattern={/^[a-zA-Z0-9_ ]*$/}
                errors={errors.generalAgentName}
                maxLength="120"
                validityMessages={{
                  required: ErrorMessages.Empty_GeneralAgentName,
                  pattern: ErrorMessages.Invalid_GeneralAgentName
                }}
              />
            </div>
            <div className="tds-col-xl-9 tds-col-md-9">
              <TrvInput
                register={register}
                name="city"
                label="City"
                required={true}
                pattern={/^[a-zA-Z0-9_ ]*$/}
                errors={errors.city}
                maxLength="120"
                validityMessages={{
                  required: ErrorMessages.Empty_City,
                  pattern: ErrorMessages.Invalid_City
                }}
              />
            </div>
            <div className="tds-col-xl-6 tds-col-md-9 tds-margin-bottom-xxx-large">
              <TrvDropdown
                register={register}
                name="state"
                label="State"
                options={States}
                required={true}
                hint="Select a State..."
                defaultValue=""
                errors={errors.state}
                validityMessages={{
                  required: ErrorMessages.Empty_State_incentive
                }}
              />
            </div>

            <div className="tds-col-xl-9 tds-col-md-9">
              <TrvInput
                register={register}
                name="trvAgencyName"
                label="Travelers Agency Name"
                required={true}
                pattern={/^[a-zA-Z0-9_ ]*$/}
                errors={errors.trvAgencyName}
                maxLength="120"
                validityMessages={{
                  required: ErrorMessages.Empty_AgencyName,
                  pattern: ErrorMessages.Invalid_AgencyName
                }}
              />
            </div>
            <div className="tds-col-xl-6 tds-col-md-9">
              <TrvInput
                register={register}
                name="trvAgencyCode"
                label="Travelers Agency Code"
                required={true}
                informationText="6 characters, beginning with zero"
                minLength="6"
                maxLength="6"
                pattern={/^0[a-zA-Z0-9]*/}
                errors={errors.trvAgencyCode}
                className="tlPrivate"
                validityMessages={{
                  required: ErrorMessages.Empty_AgencyCode,
                  pattern: ErrorMessages.Invalid_AgencyCode,
                  minLength: ErrorMessages.Invalid_AgencyCode
                }}
              />
            </div>
            <div className="tds-col-xl-6 tds-col-md-9">
              <TrvPhoneInput
                register={register}
                setValue={setValue}
                setError={setError}
                clearErrors={clearErrors}
                name="phone"
                label="Phone Number"
                minLength={10}
                pattern={/^([0-9]{3})([0-9]{3})([0-9]{4})$/}
                formatPattern="($1) $2-$3"
                required={true}
                errors={errors.phone}
                validityMessages={{
                  required: ErrorMessages.Empty_Phone_incentive,
                  minLength: ErrorMessages.Invalid_Phone
                }}
              />
            </div>
            <div className="tds-col-xl-9 tds-col-md-9">
              <TrvInput
                register={register}
                name="agencyCity"
                label="Agency City"
                required={true}
                pattern={/^[a-zA-Z0-9_ ]*$/}
                errors={errors.agencyCity}
                maxLength="120"
                validityMessages={{
                  required: ErrorMessages.Empty_AgencyCity,
                  pattern: ErrorMessages.Invalid_AgencyCity
                }}
              />
            </div>
            <div className="tds-col-xl-6 tds-col-md-9">
              <TrvDropdown
                register={register}
                name="agencyState"
                label="Agency State"
                options={States}
                required={true}
                hint="Select a State..."
                defaultValue=""
                errors={errors.agencyState}
                validityMessages={{
                  required: ErrorMessages.Empty_AgencyState
                }}
              />
            </div>
            <div className="tds-col-xl-9 tds-col-md-9 tds-margin-bottom-xxx-large">
              <TrvInput
                register={register}
                name="contactName"
                label="Contact Name"
                required={true}
                pattern={/^[a-zA-Z0-9_ ]*$/}
                errors={errors.contactName}
                maxLength="120"
                validityMessages={{
                  required: ErrorMessages.Empty_ContactName,
                  pattern: ErrorMessages.Invalid_ContactName
                }}
              />
            </div>
            <div className="tds-col-xl-9 tds-col-md-9">
              <TrvInput
                register={register}
                name="accountName"
                label="Account Name"
                required={true}
                pattern={/^[a-zA-Z0-9_ ]*$/}
                errors={errors.accountName}
                maxLength="120"
                validityMessages={{
                  required: ErrorMessages.Empty_AccountName,
                  pattern: ErrorMessages.Invalid_AccountName
                }}
              />
            </div>

            <div className="tds-col-xl-6 tds-col-md-9">
              <TrvInput
                register={register}
                name="grossWrittenPremium"
                label="Gross Written Premium"
                required={true}
                informationText="Whole Dollar, excluding taxes and fees"
                errors={errors.grossWrittenPremium}
                maxLength="10"
                pattern={/^[0-9]*$/}
                validityMessages={{
                  required: ErrorMessages.Empty_GrossWrittenPremium,
                  pattern: ErrorMessages.Invalid_GrossWrittenPremium
                }}
              />
            </div>
            <div className="tds-col-xl-6 tds-col-md-9">
              <TrvInput
                register={register}
                name="policyNumber"
                label="Policy Number"
                required={true}
                pattern={/^[a-zA-Z0-9]*$/}
                errors={errors.policyNumber}
                informationText="9 characters, ex. AB1234567"
                maxLength="9"
                className="tlPrivate"
                validityMessages={{
                  required: ErrorMessages.Empty_PolicyNumber,
                  pattern: ErrorMessages.Invalid_PolicyNumber
                }}
              />
            </div>
            <div>
              <TrvDateInput
                register={register}
                name="policyEffectiveDate"
                setError={setError}
                clearErrors={clearErrors}
                setValue={setValue}
                label="Policy Effective Date(mm/dd/yyyy)"
                min={new Date(year, 0, 1)}
                max={new Date(year, 11, 31)}
                required={true}
                informationText="Current calender year only"
                errors={errors.policyEffectiveDate}
                validityMessages={{
                  required: ErrorMessages.Empty_PolicyEffectiveDate,
                  dateValidity: ErrorMessages.Invalid_PolicyEffectiveDate,
                  dateRange: ErrorMessages.Range_PolicyEffectiveDate
                }}
              />
            </div>

            <button
              className="tds-button--primary tds-margin-top-large"
              aria-busy={isSubmissionInProgress}
              type="submit">
              Submit
            </button>
          </form>
        </div>
        <div className="tds-col-xl-2" />
      </div>
    </>
  ) : null
}

export default TravelersIncentiveForm
