import React from "react"

const EmailTableRow = ({ label, value }) => {
  return (
    <tr>
      <td>&nbsp;</td>
      <td>
        <strong>{label}</strong>
      </td>
      <td>{value}</td>
    </tr>
  )
}

export { EmailTableRow }
