import { EmailSettings } from "../../constants";
import { v4 as uuidv4 } from "uuid"

export const emailHeaders = (configs) => {
    return {
        "eefEventCorrelationId": uuidv4(),
        "eefEventApplicationName": configs.APPLICATION_NAME,
        "eefEventKeyDescription": EmailSettings.eefKeyDescription
    }
}

export const emailPayload = (formName, configs, formdata) => {
    return {
        "templateName": EmailSettings[`${formName}_TEMPLATE`],
        "contact": {
            "contactFirstName": "",
            "contactLastName": "",
            "contactEmailAddress": configs[`${formName}_TO_EMAIL_ADDRESS`],
            "contactMobileNumber": "NA"
        },
        "fromDetails": {
            "fromFirstName": "",
            "fromLastName": "",
            "fromFullName": "",
            "fromEmailAddress": formName === 'INCENTIVE_FORM' ? configs.INCENTIVE_FORM_FROM_EMAIL_ADDRESS : formdata.email
        },
        "subjectLine": configs[`${formName}_SUBJECT`],       
    }
}