import { navigate } from "gatsby"
import { store } from "../state/store"
import { sendEmail } from "../services/emailService"

const recaptchaCalled = () => {
  const state = store.getState()

  if (state.config.RECAPTCHA_ENABLED === "true") {
    window.grecaptcha.reset()
    window.grecaptcha.execute()
    return true
  }
  return false
}

const sendEmailPayloads = async (emailPayload, returnUrl) => {
  try {
    const response = await sendEmail(emailPayload)
    if (response) {
      navigate(returnUrl)
      return true
    }
  } catch (error) {}

  return false
}

const getRecaptchaPayload = (token, siteType) => {
  let recaptchaPayload = null

  if (token) {
    recaptchaPayload = {
      recaptchaToken: token,
      siteType: siteType
    }
  }
  return recaptchaPayload
}

const sentWithRecaptcha = async (
  recaptchaResponse,
  siteType,
  emailBody,
  returnUrl
) => {
  const recaptchaPayload = getRecaptchaPayload(recaptchaResponse, siteType)
  Object.assign(emailBody, { recaptchaPayload: recaptchaPayload })
  const emailSent = await sendEmailPayloads(emailBody, returnUrl)
  if (emailSent) {
    return true
  }
  return false
}

export { recaptchaCalled, sendEmailPayloads, sentWithRecaptcha }
