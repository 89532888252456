import React from "react"
import { Component } from "react"
import { store } from "../../state/store"

var script

function new_script(src) {
  //async is enabled by default
  script = document.createElement("script")
  //async is enabled by default
  script.src = src
  script.defer = true
  document.body.appendChild(script)
  if (document.querySelector("body").setActive !== undefined) {
    document.querySelector("body").setActive()
  }
}

function checkScriptExist(url) {
  var scripts = document.body.getElementsByTagName("script")
  for (var i = 0; i < scripts.length; i++) {
    if (scripts[i].src === url) {
      return true
    }
  }
  return false
}

export default class Recaptcha extends Component {
  constructor(props) {
    super(props)
    this.state = {
      widgetId: null
    }
  }

  componentDidMount() {
    //Needs to be in the global namespace for script to access it, hence window.onload
    window.onloadCallBack = function () {
      if (document.getElementById("recaptcha")) {
        if (typeof grecaptcha !== "undefined") {
          let storeState = store.getState()
          let widgetId = window.grecaptcha.render("recaptcha", {
            sitekey: storeState.config.RECAPTCHA_SITE_KEY,
            callback: "recaptchaCallBackFunction",
            "error-callback": "errorCallBackFunction",
            size: storeState.config.RECAPTCHA_SITE_TYPE,
            badge: "bottomright"
          })
          this.setState({ widgetId: widgetId })
        }
      }
    }.bind(this)

    // ensures script will only appends once
    if (
      typeof grecaptcha === "undefined" &&
      this.state.widgetId !== 0 &&
      !this.state.widgetId &&
      !checkScriptExist(
        "https://www.google.com/recaptcha/api.js?onload=onloadCallBack&render=explicit"
      )
    ) {
      new_script(
        "https://www.google.com/recaptcha/api.js?onload=onloadCallBack&render=explicit"
      )
    } else {
      //if the recaptcha script is already loaded, don't need to append it again, can just called render function
      window.onloadCallBack()
    }
    //this runs after grecaptcha.execute(). Needs to be in the global namespace for script to access it, hence window.recaptcha
    window.recaptchaCallBackFunction = function (response) {
      this.props.serviceCall(response, this.props.siteType, this.state.widgetId)
      //grecaptcha.reset(widgetId); //is done on the home page and only when policy is not found
    }.bind(this)

    //error call back when sending the wrong site ID
    window.errorCallBackFunction = function (response) {
      this.props.googleServiceError()
    }.bind(this)
  }

  render() {
    return (
      <div
        id="recaptcha"
        className="g-recaptcha"
        data-size={this.props.siteType}></div>
    )
  }
}
