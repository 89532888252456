import React from "react"
import ReactDOMServer from "react-dom/server"
import { EmailTableRow } from "./emailTemplate"
import formatDate from "../../utilities/formatDate"
import getFullStateName from "../../utilities/getFullStateName"

const TravelersIncentiveEmailTemplate = (formData) => {
  return ReactDOMServer.renderToString(
    <table>
      <EmailTableRow
        label="Issuing Insurance Company Name:"
        value={formData.companyName}
      />
      <EmailTableRow
        label="Name Of General Agent OR Program Administrator:"
        value={formData.generalAgentName}
      />
      <EmailTableRow label="City:" value={formData.city} />
      <EmailTableRow label="State:" value={getFullStateName(formData.state)} />
      <EmailTableRow
        label="Travelers Agency Name:"
        value={formData.trvAgencyName}
      />
      <EmailTableRow
        label="Travelers Agency Code:"
        value={formData.trvAgencyCode}
      />
      <EmailTableRow label="Agency City:" value={formData.agencyCity} />
      <EmailTableRow
        label="Agency State:"
        value={getFullStateName(formData.agencyState)}
      />
      <EmailTableRow label="Phone:" value={formData.phone} />
      <EmailTableRow label="Contact Name:" value={formData.contactName} />
      <EmailTableRow label="Account Name:" value={formData.accountName} />
      <EmailTableRow label="Policy Number:" value={formData.policyNumber} />
      <EmailTableRow
        label="Policy Effective Date:"
        value={formatDate(formData.policyEffectiveDate)}
      />
      <EmailTableRow
        label="Gross Written Premium:"
        value={formData.grossWrittenPremium}
      />
    </table>
  )
}

export default TravelersIncentiveEmailTemplate
