import axios from "axios"
import { store } from "../state/store"

const TrvEncyption = require('@travelers/encryption')
const keyStr = 'NORTHFIELD'
const trvProvider = TrvEncyption.cryptoProvider(keyStr)

const sendEmail = async (emailBody) => {
  try {
    const state = store.getState()
    const headers = {
      "Content-type": "application/json",
      'client_id': trvProvider.decrypt(state.config.EMAIL_CLIENT_ID), 
      'client_secret': trvProvider.decrypt(state.config.EMAIL_CLIENT_SECRET)
    }
    let response = await axios.post(state.config.EMAIL_SERVICE_URL, emailBody, {
      headers: headers
    })
    return response
  } catch (error) {}
  return null
}

export { sendEmail }
